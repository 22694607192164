* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  margin: auto;
  width: 800px;
  display: flex;
  justify-content: space-between;
}

h1 {
  font-size: 40px;
}

.posts-container {
  display: flex;
  flex-direction: column;
  width: 500px;
}

.post {
  margin-top: 20px;
  margin-bottom: 5px;
  border: #282c34 solid 1px;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.post:last-child {
  margin-bottom: 50px;
}

.post-author {
  word-break: break-word;
  font-size: 16px;
  color: gray;
}

.post-header {
  word-break: break-word;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
}

.post-body {
  word-break: break-word;
  text-align: start;
}

.create-post-container {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  width: 100%;
}

textarea {
  width: 100%;
  resize: none;
  height: 100px;
}
